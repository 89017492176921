/* eslint-disable */
import React from "react";
import { Row, Col, Container, Card, CardBody } from "reactstrap";

import img0 from "../../../assets/images/portfolio/img0.jpg";
import img1 from "../../../assets/images/portfolio/img1.jpg";
import img2 from "../../../assets/images/portfolio/img2.jpg";
import img3 from "../../../assets/images/portfolio/img3.jpg";
import img4 from "../../../assets/images/portfolio/img4.jpg";

const TopDeals = () => {
  return (
    <div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">This Month`s Top Deals</h1>
              <h6 className="subtitle">
                Leverage on our sales analytics and browse through some of our
                highest selling products and be insighted.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer">
        <Container>
          <Row className="justify-content-center">
            {/* <Col md="7" className="text-center">
              <h2 className="title">Our Recent work with three column</h2>
              <h6 className="subtitle">
                You can relay on our amazing features list and also our customer
                services will be great experience for you without doubt and in
                no-time
              </h6>
            </Col> */}
          </Row>
          <Row className="m-t-40 view-heigh">
            <Col md="6 long">
              <Card className="card-shadow">
                <a href="" className="img-ho">
                  <img
                    className="card-img-top"
                    src={img0}
                    alt="wrappixel kit"
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">Cellphones</h5>
                  <p className="m-b-0 font-14">Samsung Galaxy S23 Ultra</p>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Row>
                {/* <Col md="6">
                  <Card className="card-shadow">
                    <a href="#" className="img-ho">
                      <img
                        className="card-img-top"
                        src={img1}
                        alt="wrappixel kit"
                      />
                    </a>
                    <CardBody>
                      <h5 className="font-medium m-b-0">Cellphones</h5>
                      <p className="m-b-0 font-14">
                        Samsung Galaxy Note 20 Ultra
                      </p>
                    </CardBody>
                  </Card>
                </Col> */}
                <Col md="6 short">
                  <Card className="card-shadow">
                    <a href="#" className="img-ho">
                      <img
                        className="card-img-top"
                        src={img2}
                        alt="wrappixel kit"
                      />
                    </a>
                    <CardBody>
                      <h5 className="font-medium m-b-0">Desktops</h5>
                      <p className="m-b-0 font-14">Dell All In One</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6 short">
                  <Card className="card-shadow">
                    <a href="#" className="img-ho">
                      <img
                        className="card-img-top"
                        src={img3}
                        alt="wrappixel kit"
                      />
                    </a>
                    <CardBody>
                      <h5 className="font-medium m-b-0">Gaming Consoles</h5>
                      <p className="m-b-0 font-14">Play Station 5</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="12">
                  <Card className="card-shadow">
                    <a href="#" className="img-ho">
                      <img
                        className="card-img-top"
                        src={img4}
                        alt="wrappixel kit"
                      />
                    </a>
                    <CardBody>
                      <h5 className="font-medium m-b-0">Laptops</h5>
                      <p className="m-b-0 font-14">Asus Rog Strix 15</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TopDeals;
