import React from "react";
import { Row, Col, Container } from "reactstrap";

// import banner from "../../../assets/images/form-banners/banner1/banner-img.png";

const IndustriesComponent = () => {
  return (
    <div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center"></Row>
        </Container>
      </div>
      <div className="bg-light">
        <section>
          <div id="bannr1" className="banners spacer">
            <Container>
              <Row>
                <Col lg="6" md="5" className="align-self-center">
                  <h2 className="title font-bold">Industries We Serve...</h2>
                  <p className="m-t-40 m-b-30">
                    Our clients are spread across but not limited to these
                    industries.
                  </p>
                </Col>
                <Col lg="5" md="5" className="align-self-center m-l-30">
                  <p>State & Private Security</p>
                  <p>Media & Filming</p>
                  <p>Agriculture</p>
                  <p>Telecommunications </p>
                  <p>Real Estate & Home Automation</p>
                  <p>Education</p>
                  <p>Health</p>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
    </div>
  );
};

export default IndustriesComponent;
