import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Route, Switch, HashRouter } from "react-router-dom";

import "./assets/scss/style.scss";

// pages for this product
// import Components from "./views/components/components.jsx";
import Home from "./views/home/home.jsx";
import Products from "./views/products/products.jsx";
import Services from "./views/services/services";
import Industries from "./views/industries/industries";
import About from "./views/about/about.jsx";
import Contact from "./views/contact/contact.jsx";

var hist = createBrowserHistory();

ReactDOM.render(
  <HashRouter history={hist}>
    <Switch>
      <Route path="/contact" component={Contact} />
      <Route path="/about" component={About} />
      <Route path="/industries" component={Industries} />
      <Route path="/services" component={Services} />
      <Route path="/products" component={Products} />
      <Route path="/" component={Home} />
      {/* <Route path="/" component={Components} /> */}
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
