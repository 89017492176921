/* eslint-disable */
import React, { useState } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// import product images ************************************************

import img4 from "../../../assets/images/portfolio/img4.jpg";
import dellinsp11 from "../../../assets/images/products/dell-insp-11.jpg";
import lenovon23 from "../../../assets/images/products/lenovo_n23.jpg";
import latitude3189 from "../../../assets/images/products/dell-latitude-3189.jpg";
import latitude7280 from "../../../assets/images/products/dell-latitude-7280.jpg";
import elitebook820 from "../../../assets/images/products/hp-elitebook-820.jpg";
import elitebook840 from "../../../assets/images/products/hp-elitebook-840.jpg";
import omen from "../../../assets/images/products/hp-omen.jpg";
import pavillion250 from "../../../assets/images/products/hp-pavillion-250.jpg";
import pavilliongaming from "../../../assets/images/products/hp-pavillion-gaming.png.jpg";
import pavillion360 from "../../../assets/images/products/hp-pavillion-x360.jpg";
import inspiron13 from "../../../assets/images/products/inspiron-13-7000.jpg";
import t440 from "../../../assets/images/products/lenovo-t440.jpg";
import t470 from "../../../assets/images/products/lenovo-t470.jpg";

// **** Define product modals ***************************
const Laptops = (props) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal6, setModal6] = useState(false);
  const [modal7, setModal7] = useState(false);
  const [modal8, setModal8] = useState(false);
  const [modal9, setModal9] = useState(false);
  const [modal10, setModal10] = useState(false);
  const [modal11, setModal11] = useState(false);
  const [modal12, setModal12] = useState(false);

  // *** Define modal togglers ***********************************
  const toggle = () => {
    setModal(!modal);
  };

  const toggle1 = () => {
    setModal1(!modal1);
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };

  const toggle3 = () => {
    setModal3(!modal3);
  };

  const toggle4 = () => {
    setModal4(!modal4);
  };

  const toggle5 = () => {
    setModal5(!modal5);
  };

  const toggle6 = () => {
    setModal6(!modal6);
  };

  const toggle7 = () => {
    setModal7(!modal7);
  };

  const toggle8 = () => {
    setModal8(!modal8);
  };

  const toggle9 = () => {
    setModal9(!modal9);
  };

  const toggle10 = () => {
    setModal10(!modal10);
  };

  const toggle11 = () => {
    setModal11(!modal11);
  };

  const toggle12 = () => {
    setModal12(!modal12);
  };

  // *************************************************************

  return (
    <div>
      <div className="spacer">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h2 className="title">Laptops </h2>
              <h6 className="subtitle">
                We have a wide range of laptop brands, designs, prices and
                specifications to select from. Browse through and pick yours!
              </h6>
            </Col>
          </Row>
          <Row className="m-t-40">
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={dellinsp11}
                    alt="wrappixel kit"
                    onClick={toggle.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">Dell Inspiron 11</h5>
                  <p className="m-b-0 font-14">from $130.00</p>

                  <Modal
                    size="lg"
                    isOpen={modal}
                    toggle={toggle.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle.bind(null)}>
                      Dell Inspiron 11 3000
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={dellinsp11}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $130.00</h5>
                            <p className="m-b-0 font-14">Dual Core</p>
                            <p className="m-b-0 font-14">4GB RAM </p>
                            <p className="m-b-0 font-14">500GB HDD </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">Silver & Red Color </p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={lenovon23}
                    alt="wrappixel kit"
                    onClick={toggle1.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">Lenovo N23</h5>
                  <p className="m-b-0 font-14">from $115</p>

                  <Modal
                    size="lg"
                    isOpen={modal1}
                    toggle={toggle1.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle1.bind(null)}>
                      Lenovo N23
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={lenovon23}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $115</h5>
                            <p className="m-b-0 font-14"> Dual Core </p>
                            <p className="m-b-0 font-14">4GB Ram </p>
                            <p className="m-b-0 font-14">500GB HDD </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">USB 3.0 </p>
                            <p className="m-b-0 font-14">HDMI</p>
                            <p className="m-b-0 font-14">Battery 5HRS++</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle1.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={pavillion360}
                    alt="wrappixel kit"
                    onClick={toggle2.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">HP Pavillion X360</h5>
                  <p className="m-b-0 font-14">from $130.00</p>

                  <Modal
                    size="lg"
                    isOpen={modal2}
                    toggle={toggle2.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle2.bind(null)}>
                      HP Pavillion X360
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={img4}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $130.00</h5>
                            <p className="m-b-0 font-14">Dual Core </p>
                            <p className="m-b-0 font-14">4GB Ram </p>
                            <p className="m-b-0 font-14">500GB HDD </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">USB 3.0 </p>
                            <p className="m-b-0 font-14">HDMI</p>
                            <p className="m-b-0 font-14">Silver & Red Color</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle2.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={latitude3189}
                    alt="wrappixel kit"
                    onClick={toggle3.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">Dell Latitude 3189</h5>
                  <p className="m-b-0 font-14">$130.00</p>

                  <Modal
                    size="lg"
                    isOpen={modal3}
                    toggle={toggle3.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle3.bind(null)}>
                      Dell Latitude 3189
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={latitude3189}
                              alt="dell latitude 3189"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $120.00</h5>
                            <p className="m-b-0 font-14">Dual Core</p>
                            <p className="m-b-0 font-14">4GB Ram </p>
                            <p className="m-b-0 font-14">128GB SSD </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">USB 3.0 </p>
                            <p className="m-b-0 font-14">HDMI</p>
                            <p className="m-b-0 font-14">Battery 5HRS++</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle3.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={pavillion250}
                    alt="wrappixel kit"
                    onClick={toggle4.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">HP Pavillion 250</h5>
                  <p className="m-b-0 font-14"></p>from $140
                  <Modal
                    size="lg"
                    isOpen={modal4}
                    toggle={toggle4.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle4.bind(null)}>
                      HP Pavillion 250
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={pavillion250}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $140</h5>
                            <p className="m-b-0 font-14">Dual Core</p>
                            <p className="m-b-0 font-14">4GB Ram </p>
                            <p className="m-b-0 font-14">500 GB HDD </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">USB 3.0 </p>
                            <p className="m-b-0 font-14">HDMI</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle4.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={elitebook820}
                    alt="wrappixel kit"
                    onClick={toggle5.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">HP Elite Book 820</h5>
                  <p className="m-b-0 font-14">from $160</p>

                  <Modal
                    size="lg"
                    isOpen={modal5}
                    toggle={toggle5.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle5.bind(null)}>
                      HP Elite Book 820
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={elitebook820}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $160.00</h5>
                            <p className="m-b-0 font-14">Core i5 </p>
                            <p className="m-b-0 font-14">8GB Ram </p>
                            <p className="m-b-0 font-14">500GB HDD </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">Webcam </p>
                            <p className="m-b-0 font-14">VGA</p>
                            <p className="m-b-0 font-14">HDMI</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle5.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={elitebook840}
                    alt="wrappixel kit"
                    onClick={toggle6.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">HP Elite Book 840</h5>
                  <p className="m-b-0 font-14">from 170.00</p>

                  <Modal
                    size="lg"
                    isOpen={modal6}
                    toggle={toggle6.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle6.bind(null)}>
                      HP Elite Book 840
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={elitebook840}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $170.00</h5>
                            <p className="m-b-0 font-14">Core i5 </p>
                            <p className="m-b-0 font-14">8GB Ram </p>
                            <p className="m-b-0 font-14">500GB HDD </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">Webcam </p>
                            <p className="m-b-0 font-14">VGA</p>
                            <p className="m-b-0 font-14">HDMI</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle6.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={latitude7280}
                    alt="wrappixel kit"
                    onClick={toggle7.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">Dell Latitude 7280</h5>
                  <p className="m-b-0 font-14">from $195.00</p>

                  <Modal
                    size="lg"
                    isOpen={modal7}
                    toggle={toggle7.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle7.bind(null)}>
                      Dell Latitude 7280
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={latitude7280}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $195.00</h5>
                            <p className="m-b-0 font-14">Core i5 </p>
                            <p className="m-b-0 font-14">8GB Ram </p>
                            <p className="m-b-0 font-14">500GB HDD </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">Webcam </p>
                            <p className="m-b-0 font-14">HDMI</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle7.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={t440}
                    alt="wrappixel kit"
                    onClick={toggle8.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">Lenovo T440</h5>
                  <p className="m-b-0 font-14">from $175.00</p>

                  <Modal
                    size="lg"
                    isOpen={modal7}
                    toggle={toggle7.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle7.bind(null)}>
                      Lenovo T440
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={t440}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $175.00</h5>
                            <p className="m-b-0 font-14">Core i5 </p>
                            <p className="m-b-0 font-14">8GB Ram </p>
                            <p className="m-b-0 font-14">500GB HDD </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">Webcam </p>
                            <p className="m-b-0 font-14">HDMI</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle8.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={t470}
                    alt="wrappixel kit"
                    onClick={toggle9.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">Lenovo T470</h5>
                  <p className="m-b-0 font-14">from $220.00</p>

                  <Modal
                    size="lg"
                    isOpen={modal9}
                    toggle={toggle9.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle9.bind(null)}>
                      Lenovo T470
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={t470}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $220.00</h5>
                            <p className="m-b-0 font-14">Core i5 </p>
                            <p className="m-b-0 font-14">8GB Ram </p>
                            <p className="m-b-0 font-14">500GB HDD </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">Webcam </p>
                            <p className="m-b-0 font-14">HDMI</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle9.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={omen}
                    alt="wrappixel kit"
                    onClick={toggle10.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">HP Omen (Gaming)</h5>
                  <p className="m-b-0 font-14">from $420.00</p>

                  <Modal
                    size="lg"
                    isOpen={modal10}
                    toggle={toggle10.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle10.bind(null)}>
                      HP Omen (Gaming)
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={omen}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $420.00</h5>
                            <p className="m-b-0 font-14">Core i7</p>
                            <p className="m-b-0 font-14">16GB DDR4 Ram </p>
                            <p className="m-b-0 font-14">256GB SSD + 1TB HDD</p>
                            <p className="m-b-0 font-14">
                              4GB Dedicated Graphics
                            </p>
                            <p className="m-b-0 font-14">Keyboard Backlight </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">720MP Webcam </p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle10.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col md="4">
              <Card className="card-shadow">
                <a className="img-ho">
                  <img
                    className="card-img-top"
                    src={pavilliongaming}
                    alt="wrappixel kit"
                    onClick={toggle11.bind(null)}
                  />
                </a>
                <CardBody>
                  <h5 className="font-medium m-b-0">
                    HP Pavillion 15 (Gaming)
                  </h5>
                  <p className="m-b-0 font-14">from $420.00</p>

                  <Modal
                    size="lg"
                    isOpen={modal11}
                    toggle={toggle11.bind(null)}
                    className={props.className}
                  >
                    <ModalHeader toggle={toggle11.bind(null)}>
                      HP Pavillion 15 (Gaming)
                    </ModalHeader>
                    <ModalBody>
                      <Col md="">
                        <Card className="card-shadow">
                          <a href="#" className="img-ho">
                            <img
                              className="card-img-top"
                              src={pavilliongaming}
                              alt="wrappixel kit"
                            />
                          </a>
                          <CardBody>
                            <h5 className="font-medium m-b-0">from $420.00</h5>
                            <p className="m-b-0 font-14">Core i7</p>
                            <p className="m-b-0 font-14">16GB DDR4 Ram </p>
                            <p className="m-b-0 font-14">256GB SSD + 1TB HDD</p>
                            <p className="m-b-0 font-14">
                              4GB Dedicated Graphics
                            </p>
                            <p className="m-b-0 font-14">Keyboard Backlight </p>
                            <p className="m-b-0 font-14">Bluetoth </p>
                            <p className="m-b-0 font-14">Wifi</p>
                            <p className="m-b-0 font-14">720MP Webcam </p>
                          </CardBody>
                        </Card>
                      </Col>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle11.bind(null)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>{" "}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Laptops;
