import React from "react";
import { Row, Col, Container } from "reactstrap";

const History = () => {
  return (
    <div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">Who We Are</h1>
              <h6 className="subtitle">
                Tech X Galaxy Pvt Ltd is a dynamic African Based I.T firm that
                provides sustainable tech-solutions from low priced I.T hardware
                (Computers etc) for individuals and corporate clients, to
                sustainable low-priced Sate-llite based internet to the most
                remote communities in Africa.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-light">
        <section>
          <div className="banner spacer">
            <Container>
              <Row>
                <Col lg="6" md="6" className=" align-self-center">
                  <h3 className="font-bold">What We Do</h3>
                  <p className="m-t-40">
                    Tech X Gallaxy is partnering with willing governements and
                    corporations on Socio-economic turnaround projects accross
                    Africa. It aims at at awekening the world giant, that is
                    Africa, through revolutionalising mainly industrial |
                    manufacturing sector and her education sector as a
                    continent.
                  </p>
                </Col>
                <Col lg="6" md="6" className="align-self-center ml-auto">
                  <p className="m-t-40 m-b-30 ">
                    With over 20 establishments accross the region, we pride
                    ourselves in working towards improving the livelihood of our
                    African communities and being a portal to the world at
                    large. Our commitment is to revolutionalise the I.T sector
                    in the whole region and bring about sustainable
                    tech-solutions to address the absurd cost of I.T hardware
                    and services amidst the rising dependancy of all sectors of
                    life on technology. The high-cost of tech-hardware and
                    services in Africa remains the biggest setback in social,
                    economic and industrial development. Fortunately for you,
                    <span className="font-bold">
                      We Are Here to Challenge That.
                    </span>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
    </div>
  );
};

export default History;
