/* eslint-disable */
import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <div className="footer4 b-t spacer">
      <Container>
        <Row>
          <Col lg="3" md="6" className="m-b-30">
            <h5 className="m-b-20">Address</h5>
            <p>Shop No.7, Limbe Shopping Mall, Limbe, Blantyre, Malawi</p>
          </Col>
          <Col lg="3" md="6" className="m-b-30">
            <h5 className="m-b-20">Phone</h5>
            <p>
              Enquiries :{" "}
              <a href="265883730525" className="link">
                +265 883 730 525
              </a>
            </p>
          </Col>
          <Col lg="3" md="6" className="m-b-30">
            <h5 className="m-b-20">Email</h5>
            <p>
              Enquiries :{" "}
              <a href="mailto:enquiries@techxgalaxy.com" className="link">
                enquiries@techxgalaxy.com
              </a>{" "}
              <br />
              Sales :{" "}
              <a href="mailto:sales@techxgalaxy.com" className="link">
                sales@techxgalaxy.com
              </a>
            </p>
          </Col>
          <Col lg="3" md="6">
            <h5 className="m-b-20">Social</h5>
            <div className="round-social light">
              <a
                href="https://www.facebook.com/profile.php?id=100088536241593&mibextid=ZbWKwL"
                className="link"
              >
                <i className="fa fa-facebook"></i>
              </a>
              {/* <a href="#" className="link">
                <i className="fa fa-twitter"></i>
              </a> */}
              <a href="https://wa.me/message/5QG5GHFFX2T5O1" className="link">
                <i className="fa fa-whatsapp"></i>
              </a>
              {/* <a href="#" className="link">
                <i className="fa fa-youtube-play"></i>
              </a>
              <a href="#" className="link">
                <i className="fa fa-instagram"></i>
              </a> */}
            </div>
          </Col>
        </Row>
        <div className="f4-bottom-bar">
          <Row>
            <Col md="12">
              <div className="d-flex font-14">
                <div className="m-t-10 m-b-10 copyright">
                  All Rights Reserved.
                </div>
                <div className="links ml-auto m-t-10 m-b-10">
                  <a href="#" className="p-10 p-l-0">
                    Terms of Use
                  </a>
                  <a href="#" className="p-10">
                    Privacy Policy
                  </a>
                  <a href="#" className="p-10">
                    Designed by Zeambo
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default Footer;
