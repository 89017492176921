import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import Footer from "../../components/footer/footer.jsx";

// sections for this page
import ContentIndustries from "./sections/contentindustries.jsx";
import CallToAction from "../../components/call-to-action/CallToAction";
import HeaderMini from "../components/sections/header-mini.jsx";

const Industries = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <HeaderMini />
          <ContentIndustries />
          <CallToAction />
        </div>
      </div>
      <Footer />
    </div>
  );
};

Industries.propTypes = {
  classes: PropTypes.object,
};

export default Industries;
