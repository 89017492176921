/* eslint-disable */
import React from "react";
import { Row, Col, Container } from "reactstrap";

const Team = () => {
  return (
    <div>
      <div className="spacer team2">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h2 className="title">Our Experienced & Professional Team</h2>
              <h6 className="subtitle">
                Meet the team behind the mighty works being undertaken by Tech X
                Gallaxy. Its here to serve you and you.
              </h6>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="3" md="6">
              <Row className="no-gutters">
                <Col md="12" className="pro-pic t1">
                  <div className="card-img-overlay">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="https://www.facebook.com/TechxGalaxy/about_overview">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://wa.me/message/971527758103">
                          <i className="fa fa-whatsapp"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12 text-center">
                  <div className="p-t-10">
                    <h5 className="title font-medium ">Patson Chapanduka</h5>
                    <h6 className="subtitle">C.E.O & Founder</h6>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="7">
              <p className="text-center">
                Entrepreneur by Preffesion with MA in Entrepreneurship
                EUCLEA(France), Executive MBA in Digital Marketing from EUCLEA
                Bussines School (France) , Certificate in Global Bussines
                (Havard Bussines School), Certified Bussines Development Manager
                with the Chatterd Management Institute(UK),Certificate Real
                Estate Investment Analysis.
              </p>
            </Col>
          </Row>
          <Row className="m-t-30">
            <Col lg="3" md="6" className="m-b-30">
              <Row className="no-gutters">
                <Col md="12" className="col-md-12 pro-pic t2">
                  <div className="card-img-overlay">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <i className="fa fa-whatsapp"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="12">
                  <div className="p-t-10 text-center">
                    <h5 className="title font-medium">
                      Panashe Naome Nyambira
                    </h5>
                    <h6 className="subtitle">Director Operations</h6>
                    <p>
                      ISO Certified Asset Manager and Data Analyst with a Bsc
                      Honors in Chemical and Process Engineering
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg="3" md="6" className="m-b-30">
              <Row className="no-gutters">
                <Col md="12" className="col-md-12 pro-pic t3">
                  <div className="card-img-overlay">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <i className="fa fa-whatsapp"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="12">
                  <div className="p-t-10 text-center">
                    <h5 className="title font-medium">Liam W Tsampwayi</h5>
                    <h6 className="subtitle">Managing Director Malawi</h6>
                    <p>
                      Bsc Computer Engineering, Diploma In Bussines Management
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg="3" md="6" className="m-b-30">
              <Row className="no-gutters">
                <Col md="12" className="col-md-12 pro-pic t4">
                  <div className="card-img-overlay">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="https://www.facebook.com/Zeam.bo.ben">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://wa.me/message/263776390246">
                          <i className="fa fa-whatsapp"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="12">
                  <div className="p-t-10 text-center">
                    <h5 className="title font-medium">Benedict Mbiri</h5>
                    <h6 className="subtitle">Director Geospatial Solutions</h6>
                    <p>
                      A Geospatial Intelligence Analyst with a Bsc Hons in
                      GEOINT, keen attention to detail and proficiency in
                      solving socio-economic problems using the science of
                      WHERE.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg="3" md="6" className="m-b-30">
              <Row className="no-gutters">
                <Col md="12" className="col-md-12 pro-pic t5">
                  <div className="card-img-overlay">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="https://www.facebook.com/rightrenius">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://wa.me/message/260776654715">
                          <i className="fa fa-whatsapp"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="12">
                  <div className="p-t-10 text-center">
                    <h5 className="title font-medium">Renius Chihoko</h5>
                    <h6 className="subtitle">Managing Director Zambia</h6>
                    <p>
                      A renowned entrepreneur and business administrator with
                      exceptional skills in strategic planning, financial
                      management, and team leadership.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Team;
