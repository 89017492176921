import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import Footer from "../../components/footer/footer.jsx";

// sections for this page
import CallToAction from "../../components/call-to-action/CallToAction";
import Laptops from "./sections/laptops.jsx";
import HeaderMini from "../components/sections/header-mini.jsx";

const Products = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <HeaderMini />
          <Laptops />
          <CallToAction />
        </div>
      </div>
      <Footer />
    </div>
  );
};

Products.propTypes = {
  classes: PropTypes.object,
};

export default Products;
