/* eslint-disable */
import React, { useState } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import img1 from "../../../assets/images/services/network.jpg";
import img2 from "../../../assets/images/services/security.jpg";
import img3 from "../../../assets/images/services/repairs.jpg";
import img4 from "../../../assets/images/services/geospatial.jpg";

const FeatureServices = (props) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const toggle1 = () => {
    setModal1(!modal1);
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };

  const toggle3 = () => {
    setModal3(!modal3);
  };

  return (
    <div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">What We Offer</h1>
              <h6 className="subtitle">
                We offer a fairly wide range of I.T solutions and services. Here
                are some of these services breifly explained.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer feature4">
        <Container>
          {/* <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h2 className="title">Awesome with Extra Ordinary Flexibility</h2>
              <h6 className="subtitle">
                You can relay on our amazing features list and also our customer
                services will be great experience for you without doubt and in
                no-time
              </h6>
            </Col>
          </Row> */}
          <Row className="m-t-20">
            <Col md="6" className="wrap-feature4-box">
              <Card>
                <CardBody>
                  <div className="icon-round bg-light-info">
                    <i className="fa fa-wifi"></i>
                  </div>
                  <h5 className="font-medium">Network Solutions</h5>
                  <p className="m-t-20">
                    In a bid to deal with every rising cost of internet access
                    in Africa, We partner with some of the best satelite
                    internet...
                  </p>
                  <Col md="6">
                    <Button
                      type="button"
                      onClick={toggle.bind(null)}
                      className="btn btn-block waves-effect waves-light btn-outline-primary m-b-30"
                    >
                      Explore More
                    </Button>

                    {/* Modal start */}

                    <Modal
                      size="lg"
                      isOpen={modal}
                      toggle={toggle.bind(null)}
                      className={props.className}
                    >
                      <a href="#" className="img-ho">
                        <img
                          className="card-img-top"
                          src={img1}
                          alt="wrappixel kit"
                        />
                      </a>
                      <ModalHeader toggle={toggle.bind(null)}>
                        Internet Solutions
                      </ModalHeader>
                      <ModalBody>
                        In a bid to deal with every rising cost of internet
                        access in Africa, We partner with some of the best
                        satellite internet service providers to serve our
                        communities with low-cost internet access. We are also a
                        supplier of high quality networking hardware including
                        Servers, Server Racks, Switches, Routers, Cables etc,
                        Our services give consice solutions to all your
                        corporate and home needs. To our casual internet users,
                        we are establishing a vast network of high-speed
                        internet Cyber Cafes accross the region to catter for
                        your needs.
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={toggle.bind(null)}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>

                    {/* Modal end */}
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" className="wrap-feature4-box">
              <Card>
                <CardBody>
                  <div className="icon-round bg-light-info">
                    <i className="fa fa-shield"></i>
                  </div>
                  <h5 className="font-medium">Security Systems </h5>
                  <p className="m-t-20">
                    We provide you with the most comprehensive security
                    solutions. Our system employs the most advanced tech.....{" "}
                  </p>
                  <Col md="6">
                    <Button
                      type="button"
                      onClick={toggle1.bind(null)}
                      className="btn btn-block waves-effect waves-light btn-outline-primary m-b-30"
                    >
                      Explore More
                    </Button>

                    {/* Modal start */}

                    <Modal
                      size="lg"
                      isOpen={modal1}
                      toggle={toggle1.bind(null)}
                      className={props.className}
                    >
                      <a href="#" className="img-ho">
                        <img
                          className="card-img-top"
                          src={img2}
                          alt="wrappixel kit"
                        />
                      </a>

                      <ModalHeader toggle={toggle1.bind(null)}>
                        Security Systems
                      </ModalHeader>
                      <ModalBody>
                        We provide you with the most comprehensive security
                        solutions. Our system employs the most advanced
                        technology, for monitoring your business properties,
                        vehicles, family down to your child. We handle all your
                        needs from security system design, CCTV installations,
                        buglar deterence and emmergency reporting systems,
                        vehicle tracking, as well as child/minor tracking.
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={toggle1.bind(null)}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>

                    {/* Modal end */}
                  </Col>
                </CardBody>
              </Card>
            </Col>

            <Col md="6" className="wrap-feature4-box">
              <Card>
                <CardBody>
                  <div className="icon-round bg-light-info">
                    <i className="fa fa-microchip"></i>
                  </div>
                  <h5 className="font-medium">
                    Computer Repair & Maintenance{" "}
                  </h5>
                  <p className="m-t-20">
                    Our highly skilled technicians are redily available for all
                    your systems maintenance, software.....{" "}
                  </p>
                  <Col md="6">
                    <Button
                      type="button"
                      onClick={toggle2.bind(null)}
                      className="btn btn-block waves-effect waves-light btn-outline-primary m-b-30"
                    >
                      Explore More
                    </Button>
                    {/* Modal start */}

                    <Modal
                      size="lg"
                      isOpen={modal2}
                      toggle={toggle2.bind(null)}
                      className={props.className}
                    >
                      <a href="#" className="img-ho">
                        <img
                          className="card-img-top"
                          src={img3}
                          alt="wrappixel kit"
                        />
                      </a>

                      <ModalHeader toggle={toggle2.bind(null)}>
                        Computer Repair & Maintenance
                      </ModalHeader>
                      <ModalBody>
                        Our highly skilled technicians are redily available for
                        all your systems maintenance, software upgrades,
                        laptop/desktop repairs, from broken computer LCDs,
                        corrupt HDDs/SSDs, to Software and Operating System
                        Installations, etc.
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={toggle2.bind(null)}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>

                    {/* Modal end */}
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" className="wrap-feature4-box">
              <Card>
                <CardBody>
                  <div className="icon-round bg-light-info">
                    <i className="fa fa-globe"></i>
                  </div>
                  <h5 className="font-medium">Geospatial Solutions </h5>
                  <p className="m-t-20">
                    Unlock the power of location with our Geospatial
                    Intelligence Division. We provide cutting-edge.....{" "}
                  </p>
                  <Col md="6">
                    <Button
                      type="button"
                      onClick={toggle3.bind(null)}
                      className="btn btn-block waves-effect waves-light btn-outline-primary m-b-30"
                    >
                      Explore More
                    </Button>
                    {/* Modal start */}

                    <Modal
                      size="lg"
                      isOpen={modal3}
                      toggle={toggle3.bind(null)}
                      className={props.className}
                    >
                      <a href="#" className="img-ho">
                        <img
                          className="card-img-top"
                          src={img4}
                          alt="wrappixel kit"
                        />
                      </a>

                      <ModalHeader toggle={toggle3.bind(null)}>
                        Geospatial Solutions
                      </ModalHeader>
                      <ModalBody>
                        <p>
                          Unlock the power of location with our Geospatial
                          Intelligence Division. We provide cutting-edge
                          geospatial solutions that transform businesses. From
                          GIS and Remote Sensing to Mapping and Visualization,
                          we tailor services to your needs. Gain a competitive
                          edge in urban planning, resource management,
                          logistics, agriculture and more.
                        </p>
                        <p>
                          Streamline operations, make data-driven choices, and
                          seize hidden oppotunities. Partner with Tech X Galaxy
                          today to unlock your true potential and transformative
                          geospatial intelligence.
                        </p>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={toggle3.bind(null)}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>

                    {/* Modal end */}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default FeatureServices;
