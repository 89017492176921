/* eslint-disable */
import React from "react";
import { Row, Col, Container, Card, CardBody } from "reactstrap";

import img5 from "../../../assets/images/features/feature30/img1.jpg";

const Featured = () => {
  return (
    <div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">Features</h1>
              <h6 className="subtitle">
                A handful of technologies are introduced onto the market every
                day. We are devoted to testing them, experience them and assess
                them for you. We are here to serve you.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer ">
        <Container className="feature30">
          <Row>
            <Col lg="10">
              <img
                src={img5}
                className="rounded img-responsive"
                alt="wrappixel"
              />
            </Col>
            <Col lg="5" md="7" className="text-center wrap-feature30-box">
              <Card className="card-shadow">
                <CardBody>
                  <div className="p-20">
                    <span className="label label-info label-rounded">
                      Agriculture Drones
                    </span>
                    <h3 className="title">Technology today is super dynamic</h3>
                    <p>
                      You can rely on our amazing featured products and embrace
                      the future. Our customer services is there to answer your
                      questions, should you have some. Get in touch with us and
                      we will surely help.
                    </p>
                    {/* <a
                      className="btn btn-info-gradiant btn-md btn-arrow m-t-20"
                      href="#"
                    >
                      <span>
                        Explore More <i className="ti-arrow-right"></i>
                      </span>
                    </a> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Featured;
