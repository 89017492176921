import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import HeaderBanner from "../../components/banner/banner.jsx";
import Footer from "../../components/footer/footer.jsx";

// sections for this page
import TopDeals from "./sections/top-deals.jsx";
import Industries from "./sections/industries.jsx";
import Featured from "./sections/featured.jsx";
import CallToAction from "../../components/call-to-action/CallToAction";
import Testimonials from "./sections/testimonials.jsx";

const Home = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <HeaderBanner />
          <TopDeals />
          <Industries />
          <Featured />
          <Testimonials />
          <CallToAction />
        </div>
      </div>
      <Footer />
    </div>
  );
};

Home.propTypes = {
  classes: PropTypes.object,
};

export default Home;
