/* eslint-disable */
import React from "react";
import { Row, Col, Container } from "reactstrap";

import agriculture from "../../../assets/images/industries/agriculture.jpg";
import education from "../../../assets/images/industries/education.jpg";
import health from "../../../assets/images/industries/health.jpg";
import mediaandfilm from "../../../assets/images/industries/mediaandfilm.jpg";
import realestate from "../../../assets/images/industries/realestate.jpg";
import security from "../../../assets/images/industries/security.jpg";
import telecommunication from "../../../assets/images/industries/telecommunication.jpg";


const ContentIndustries = () => {
  return (
    <div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">State & Private Security</h1>
              <h6 className="subtitle">
                We make protecting clients, properties and communities easier,
                like it is said "We are our brothers keeper." TXG in partnership
                with the top security systems developers in the world bring you
                comprehensive security solutions for your bussines premises,
                homes, burglar deterence, vehicle tracking, child (minor)
                tracking, drone surveillance, immergency distress signalling,
                down to cyber security.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer ">
        <Container className="feature30">
          <Row>
            <Col lg="12">
              <img
                src={security}
                className="rounded img-responsive"
                alt="wrappixel"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">Media & Filming</h1>
              <h6 className="subtitle">
                We provide some of the most sophisticated automated/ remote
                controlled irrigation, field/greenhouse monitoring and control
                systems. Our drone field geo-surveillance and field fumigation
                system is the most comprehensive tech of its type.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer ">
        <Container className="feature30">
          <Row>
            <Col lg="12">
              <img
                src={mediaandfilm}
                className="rounded img-responsive"
                alt="wrappixel"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">Agriculture</h1>
              <h6 className="subtitle">
                We provide some of the most sophisticated automated/ remote
                controlled irrigation, field/greenhouse monitoring and control
                systems. Our drone field geo-surveillance and field fumigation
                system is the most comprehensive tech of its type.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer ">
        <Container className="feature30">
          <Row>
            <Col lg="12">
              <img
                src={agriculture}
                className="rounded img-responsive"
                alt="wrappixel"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">Telecommunications</h1>
              <h6 className="subtitle">
                Our technology products and services have enabled people to
                efficiently and effectively communicate through emails, instant
                messaging, video confferencing and voice over internet protocols
                (VOIP) among other things. A significant increase in
                connectivity, cost savings, improved internet performance and
                inturn, improved perfomance has been witnessed by our clients
                from the day our company was established.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer ">
        <Container className="feature30">
          <Row>
            <Col lg="12">
              <img
                src={telecommunication}
                className="rounded img-responsive"
                alt="wrappixel"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">Health</h1>
              <h6 className="subtitle">
                Technology has revolutionized the healthcare inductry, making it
                more efficient, accurate, and accessible. Electronic Health
                Records (EHRs) have replaced paper-based records, allowing for
                easy access to patient information and reducing errors.
                Telemadicine has made healthcare more accessible by providing
                remote consultations, especially during the COVID-19 pandemic.
              </h6>
              <h6 className="subtitle">
                Virtual Reality (VR) is being used in therapy and rehabilitation
                to help patients overcome anxiety and phobias. Robotics
                technology, Blockchain technology, 3D printing, et cetera, have
                been introduced and brought about positive change into the
                industry. Tech X Gallaxy in here to support this and a dozen
                other things.{" "}
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer ">
        <Container className="feature30">
          <Row>
            <Col lg="12">
              <img
                src={health}
                className="rounded img-responsive"
                alt="wrappixel"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">Real Estate</h1>
              <h6 className="subtitle">
                We have been and still transforming the real estate industry in
                Africa. We provide services and products to promote online
                property listings, virtual and augmented reality systems,
                electronic signatures, big data analytics, blockchain, smart
                homes and Internet of Things (IoT). Don`t be left out, Get in
                touch today and be part of something great.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer ">
        <Container className="feature30">
          <Row>
            <Col lg="12">
              <img
                src={realestate}
                className="rounded img-responsive"
                alt="wrappixel"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">Education</h1>
              <h6 className="subtitle">
                Your one-stop shop for all your I.T hardware, Customised
                Learning aids, Virtual Classroom/virtual meeting system setup
                specifically desgned to meet the ever increasing demands for
                continuation of either school or bussines management inlight of
                the Corona virus global pandemic
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="spacer ">
        <Container className="feature30">
          <Row>
            <Col lg="12">
              <img
                src={education}
                className="rounded img-responsive"
                alt="wrappixel"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ContentIndustries;
