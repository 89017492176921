/* eslint-disable */
import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import {
  Container,
  NavbarBrand,
  Navbar,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse,
} from "reactstrap";

import logo from "../../assets/images/logos/logo-white.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/

  return (
    <div className="topbar" id="top">
      <div className="header6">
        <Container className="po-relative">
          <Navbar className="navbar-expand-lg h6-nav-bar">
            <NavbarBrand href="/">
              <img src={logo} alt="wrapkit" />
            </NavbarBrand>
            <NavbarToggler onClick={toggle}>
              <span className="ti-menu"></span>
            </NavbarToggler>
            <Collapse
              isOpen={isOpen}
              navbar
              className="hover-dropdown font-14 ml-auto"
              id="h6-info"
            >
              <Nav navbar className="ml-auto">
                <NavItem>
                  <Link className="nav-link" to={"/Home"}>
                    Home
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to={"/Products"}>
                    Products
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to={"/Services"}>
                    Services
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to={"/industries"}>
                    Industries
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to={"/About"}>
                    About Us
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to={"/Contact"}>
                    Contact Us
                  </Link>
                </NavItem>
              </Nav>
              <div className="round-social light">
                <a
                  href="https://www.facebook.com/profile.php?id=100088536241593&mibextid=ZbWKwL"
                  className="link"
                >
                  <i className="fa fa-facebook ml-50"></i>
                </a>
                <a
                  href="https://wa.me/message/5QG5GHFFX2T5O1"
                  className="link "
                >
                  <i className="fa fa-whatsapp "></i>
                </a>
              </div>
            </Collapse>
          </Navbar>
        </Container>
      </div>
    </div>
  );
};
export default Header;
