/* eslint-disable */
import React from "react";
import { Row, Col, Container, Card, CardBody } from "reactstrap";

import img1 from "../../../assets/images/testimonial/1.jpg";

const Testimonials = () => {
  return (
    <div>
      <div className="spacer"></div>
      <div className="testimonial3 spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h2 className="title">Check what our Customers are Saying</h2>
              <h6 className="subtitle">
                Through our years in business, we have left a footprint of happy
                customers. You are welcome to send us your testimonial as well
                and be part of Tech X Gallaxy statistics.
              </h6>
            </Col>
          </Row>
          <Row className="testi3 m-t-40 justify-content-center">
            <Col lg="4" md="6">
              <Card className="card-shadow">
                <CardBody>
                  <h6 className="font-light m-b-30">
                    “Tech X Galaxy is one of the best tech company I have ever
                    worked with. They provide innovative solutions that help me
                    grow my business and achieve my goals. They are reliable,
                    responsive and friendly. I highly recommend them to anyone
                    looking for a tech partner.”
                  </h6>
                  <div className="d-flex no-block align-items-center">
                    <span className="thumb-img">
                      <img src={img1} alt="wrapkit" className="circle" />
                    </span>
                    <div className="m-l-20">
                      <h6 className="m-b-0 customer">Tshax - Miningo Tech</h6>
                      <div className="font-10">
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-muted">
                          <i className="fa fa-star"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-shadow">
                <CardBody>
                  <h6 className="font-light m-b-30">
                    "I have been working with Tech X Galaxy for over 5 years and
                    I am very impressed by their professionalism, creativity and
                    quality of service. They always deliver on time and exceed
                    my expectations. They are not just a vendor, they are a
                    partner in my success.”
                  </h6>
                  <div className="d-flex no-block align-items-center">
                    <span className="thumb-img">
                      <img src={img1} alt="wrapkit" className="circle" />
                    </span>
                    <div className="m-l-20">
                      <h6 className="m-b-0 customer">Richard - Future Tech</h6>
                      <div className="font-10">
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-muted">
                          <i className="fa fa-star"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-shadow">
                <CardBody>
                  <h6 className="font-light m-b-30">
                    “I have been using Tech X Galaxy's products and services for
                    a long time and I am very satisfied with them. They always
                    update their features and fix any issues quickly. They also
                    offer excellent customer support and training. They are the
                    ultimate tech company for me. Kudos!”
                  </h6>
                  <div className="d-flex no-block align-items-center">
                    <span className="thumb-img">
                      <img src={img1} alt="wrapkit" className="circle" />
                    </span>
                    <div className="m-l-20">
                      <h6 className="m-b-0 customer">Valerie - V Circle</h6>
                      <div className="font-10">
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-success">
                          <i className="fa fa-star"></i>
                        </a>
                        <a href="" className="text-muted">
                          <i className="fa fa-star"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Testimonials;
